import Card from "antd/es/card";
import "antd/es/card/style/index.js";
import React from "react";
import ContentSection from "../common/ContentSection";
import "./Skills.less";
var skills = [
    {
        icon: require("./img/go.svg"),
        title: "golang",
        url: "https://golang.org/",
    },
    {
        icon: require("./img/java.svg"),
        title: "java ee",
        url: "https://www.oracle.com/technetwork/java/javaee/overview/index.html",
    },
    {
        icon: require("./img/postgres.svg"),
        title: "postgresql",
        url: "https://www.postgresql.org/",
    },
    {
        icon: require("./img/docker.svg"),
        title: "docker",
        url: "https://www.docker.com/",
    },
    {
        icon: require("./img/typescript.svg"),
        title: "typescript",
        url: "https://www.typescriptlang.org/",
    },
    {
        icon: require("./img/react.svg"),
        title: "react",
        url: "https://reactjs.org/",
    },
];
var Skills = function () { return (React.createElement(ContentSection, { title: "Expertise", subTitle: "The technologies I am proficient in", wrapperClass: "skills" },
    React.createElement("div", { className: "skills-card-row materialize" }, skills.map(function (skill) { return (React.createElement("a", { key: skill.title, href: skill.url, target: "_blank", rel: "noopener noreferrer" },
        React.createElement(Card, { className: "skills-card", hoverable: true, cover: React.createElement("img", { alt: skill.title, src: skill.icon }) },
            React.createElement("div", { className: "skills-card-meta" }, skill.title)))); })))); };
export default Skills;
